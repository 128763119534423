import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  List,
  ListItem,
  Stack
} from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import { ChatControlBar } from "../components/ChatControlBar";
import ResponseComponent from "../components/ResponseComponent";
import { ChatData, UserType } from "../models/ChatData";
import { getBotResponse, getGreetingMessage } from "../services/chat.service";
import "./chatbot.css";
import "./message.css";
import "./typing.css";
import { AdsCard } from "../components/ads/AdsCard";

interface ChatBotProps {}

export const ChatBot: React.FC<ChatBotProps> = () => {
  const [busy, setBusy] = useState<boolean>(false);
  const [chatDataList, setChatDataList] = useState<ChatData[]>([
    {
      message: "Hello!, " + getGreetingMessage(),
      userType: UserType.BOT,
      answerType: "text",
      answer: "",
      dataType: ""
    }
  ]);

  const search = async (query: string) => {
    // console.log("query ::::", query);
    if (query) {
      query = query.trim().toLowerCase();
      setBusy(true);
      let temp = [...chatDataList];
      const userChat = {} as ChatData;
      userChat.message = query;
      userChat.userType = UserType.USER;
      userChat.answerType = "text";
      temp.push(userChat);
      setChatDataList(temp);
      // console.log("after chatDataList ::::", chatDataList);
      const response = await getBotResponse(query);
      if (response) {
        // console.log("final to user ....response :: ", response);
        const botChat = {} as ChatData;
        botChat.message = response.message;
        botChat.answerType = response.answerType;
        botChat.answer = response.answer;
        botChat.userType = UserType.BOT;
        botChat.service = response.service;
        botChat.dataType = response.dataType;
        temp.push(botChat);
        setChatDataList(temp);
      }
      setBusy(false);
    }
  };

  return (
    <>
      <div className="chat-container">
        {/* <SpeedDialComponent></SpeedDialComponent> */}

        <div className="chat-module">
          <div className="thread">
            <List>
              {chatDataList.length > 0 &&
                chatDataList.map((item, index) => (
                  <React.Fragment key={index}>
                    <CustomListItem className="thread-item">
                      <ResponseComponent chatData={item} />
                    </CustomListItem>
                  </React.Fragment>
                ))}
            </List>
          </div>

          <Grid container spacing={3}>
            <Grid item xs></Grid>
            <Grid item xs={6}>
              <img
                src="demo_picosearch.gif"
                id="demo"
                style={{ width: "180px", height: "300px" }}
              ></img>
            </Grid>
            <Grid item xs></Grid>
          </Grid>
        </div>
      </div>
      <ChatControlBar sendMsg={search}></ChatControlBar>
    </>
  );
};

export const CustomPanel = styled.div`
  margin-bottom: auto;
`;

export const CustomListItem = styled(ListItem)`
  margin-bottom: auto;
  margin-top: auto;
`;
