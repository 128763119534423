import { Global } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Typography from "@mui/material/Typography";
import { green, grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import React, { FC } from "react";

const drawerBleeding = 56;
interface Page3ComponentProps {
  window?: () => Window;
  show: boolean;
  data: any;
  close: (title: string) => void;
  title: string;
}

const StyledBox = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800]
}));

const Puller = styled("div")(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)"
}));

export const Page3Component: FC<Page3ComponentProps> = ({
  window,
  show,
  close,
  title
}) => {
  const [open, setOpen] = React.useState(show);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const handleClose = () => {
    close("Page 3");
  };
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      {open && (
        <>
          <CssBaseline />
          <Global
            styles={{
              ".MuiDrawer-root > .MuiPaper-root": {
                height: `calc(85% - ${drawerBleeding}px)`,
                overflow: "visible"
              }
            }}
          />
          <SwipeableDrawer
            container={container}
            anchor="bottom"
            open={open}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            swipeAreaWidth={drawerBleeding}
            disableSwipeToOpen={false}
            ModalProps={{
              keepMounted: true
            }}
          >
            <StyledBox
              sx={{
                position: "absolute",
                top: -drawerBleeding,
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                visibility: "visible",
                right: 0,
                left: 0
              }}
            >
              <Puller />
              <Typography sx={{ p: 2, color: green[500] }}>
                <span style={{ fontSize: "20px" }}>{title}</span>
                <CloseIcon
                  style={{ marginLeft: "60%" }}
                  onClick={handleClose}
                ></CloseIcon>
              </Typography>
            </StyledBox>
            <StyledBox
              sx={{
                px: 2,
                pb: 2,
                height: "100%",
                overflow: "auto",
                backgroundColor: "black"
              }}
            >
              <img
                src="coming_soon.jpeg"
                id="coming_soon"
                style={{ width: "100%", marginTop: "50%" }}
              ></img>
            </StyledBox>
          </SwipeableDrawer>
        </>
      )}
    </>
  );
};

export const CustomButton = styled(Button)`
  border: 1px solid #ddd; /* Gray border */
  border-radius: 20px; /* Rounded border */
  padding: 2px; /* Some padding */
  width: 100px;
  height: 100px;
  margin-top: 10px;
  /* Set a small width */
`;
