import React, { useState, useEffect } from "react";
import SearchForm from "./components/SearchForm";
import { isInsideGeofence, text2Speech } from "./services/geo.service";
import { Typography } from "@mui/material";

import NotificationComponent from "./components/NotificationComponent";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { GeoRestrictionDialog } from "./components/GeoRestrictionDialog";
import LandingPage from "./LandingPage";
import MobilePage from "./MobilePage";

const App: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isMobile, setIsMobile] = useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [geoFenceFlag, setGeoFenceFlag] = React.useState(false);

  const successCallback = (position: GeolocationPosition) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    if (isInsideGeofence(latitude, longitude)) {
      setShowAlert(true);
      setGeoFenceFlag(true);
      console.log("Inside geofence.");
      if (searchTerm && searchTerm.trim().length > 0) {
      }
    } else {
      console.log("Outside geofence.");
      setShowAlert(true);
      setGeoFenceFlag(false);
    }
  };

  const errorCallback = (error: GeolocationPositionError) => {
    console.error("Error accessing geolocation:", error.message);
  };

  useEffect(() => {
    // checkGeoFence();
    const userAgent = window.navigator.userAgent;
    const mobileKeywords = [
      "Mobile",
      "iPhone",
      "Android",
      "Windows Phone",
      "BlackBerry"
    ];
    const checkIsMobile = (): void => {
      const isMobileDevice = mobileKeywords.some((keyword) =>
        userAgent.includes(keyword)
      );
      setIsMobile(isMobileDevice);
      // setIsMobile(true);
    };
    checkIsMobile();
  }, []);

  return (
    <>
      {isMobile ? (
        <LandingPage></LandingPage>
      ) : (
        <MobilePage></MobilePage>
        // <div>
        //   <Card sx={{ minWidth: 275 }}>
        //     <CardContent>
        //       <Typography
        //         sx={{ fontSize: 14 }}
        //         color="text.secondary"
        //         gutterBottom
        //       >
        //         Use mobile device
        //       </Typography>
        //       <Typography variant="body2">
        //         This website is only accessible through a mobile device. Please
        //         visit using a mobile device to access the content.
        //       </Typography>
        //     </CardContent>
        //   </Card>
        // </div>
      )}
      <NotificationComponent
        show={showAlert}
        flag={geoFenceFlag}
        message={
          geoFenceFlag
            ? "Inside NIKOO HOMES-II premises"
            : "Accessing the restricted data outside the NIKOO HOMES-II premises is not permitted."
        }
      ></NotificationComponent>
      <GeoRestrictionDialog
        checkGeoFenceFlag={false}
        errorCallback={errorCallback}
        successCallback={successCallback}
      />

      {/* <AppBar position="static">

      </AppBar>
      {isMobile ? (
        <div style={{ marginTop: "20px" }}>
          <SearchForm
            searchData={searchData}
            searchResultData={searchResultData}
          />
          {!searchResults && searchTerm && <LinearProgress />}
          <br />
          {searchResults && (
            <SearchResults results={searchResults} queryData={searchTerm} />
          )}
        </div>
      ) : (
        <div>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Use mobile device
              </Typography>
              <Typography variant="body2">
                This website is only accessible through a mobile device. Please
                visit using a mobile device to access the content.
              </Typography>
            </CardContent>
          </Card>
        </div>
      )}
      <NotificationComponent
        show={showAlert}
        flag={geoFenceFlag}
        message={
          geoFenceFlag
            ? "Inside NIKOO HOMES-II premises"
            : "Accessing the restricted data outside the NIKOO HOMES-II premises is not permitted."
        }
      ></NotificationComponent>
      <GeoRestrictionDialog
        checkGeoFenceFlag={false}
        errorCallback={errorCallback}
        successCallback={successCallback}
      /> */}
    </>
  );
};

export default App;
