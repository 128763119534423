import {
  Button,
  Card,
  CardContent,
  Chip,
  Stack,
  Typography
} from "@mui/material";
import { FC } from "react";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { Address } from "../../models/Address";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import SubwayOutlinedIcon from "@mui/icons-material/SubwayOutlined";

interface AddressTypeComponentProps {
  addressItem: Address;
}

export const AddressTypeComponent: FC<AddressTypeComponentProps> = ({
  addressItem
}) => {
  return (
    <>
      <Card
        sx={{
          width: "100%",
          padding: "1%",
          paddingTop: "0px",
          paddingBottom: "0px"
        }}
      >
        <CardContent>
          <Typography
            sx={{ fontSize: 14 }}
            color="text.secondary"
            gutterBottom
            style={{ fontWeight: "bold" }}
          >
            <Stack spacing={2} direction="row" alignItems="center">
              {addressItem.entityType === "Building" && <HomeWorkIcon />}
              {addressItem.entityType === "Person" && (
                <AccountCircleOutlinedIcon />
              )}
              {addressItem.entityType === "Train" && <SubwayOutlinedIcon />}
              <Typography
                noWrap
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
                style={{ fontWeight: "bold" }}
              >
                {addressItem.title}
              </Typography>
            </Stack>

            {/* <span style={{ verticalAlign: "super" }}>{addressItem.title}</span> */}
          </Typography>
          <Typography>{addressItem.address}</Typography>
          {addressItem.timing &&
            addressItem.timing.map((item, index) => (
              <Chip
                icon={<EventAvailableOutlinedIcon />}
                label={item}
                variant="outlined"
              />
            ))}
        </CardContent>
        {addressItem.phone &&
          addressItem.phone.map((item, index) => (
            <a href={`tel:${item}`}>
              <Button
                key={item}
                style={{
                  marginLeft: "10px",
                  marginBottom: "2px",
                  fontWeight: "bold",
                  color: "#000"
                }}
              >
                <LocalPhoneIcon color="primary" /> {item}
              </Button>
            </a>
          ))}
      </Card>
      <br />
    </>
  );
};
