export enum DATA_TYPE {
  TEXT = "text",
  EMAIL = "email",
  PHONE = "phone",
  WHATSAPP_LINKS = "whatsapp",
  TRAIN = "train",
  ADDRESS = "address"
}

export enum ANSWER_TYPE {
  TEXT = "text",
  CARD = "card",
  PHONE = "phone",
  LIST = "list"
}

export enum ACTIVITY_STATUS {
  ACTIVE = "ACTIVE",
  PRIVATE = "IN_ACTIVE"
}

export enum ACCESS_STATUS {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE"
}

export enum PAGE_TYPE {
  CATEGORY_PAGE = "Category",
  PAGE3_PAGE = "Page 3"
}
