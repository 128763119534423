import { Card, CardContent, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { WhatsApp } from "../../models/WhatsApp";
import styled from "styled-components";

interface WhatsAppTypeComponentProps {
  whatsAppItem: WhatsApp;
}

export const WhatsAppTypeComponent: FC<WhatsAppTypeComponentProps> = ({
  whatsAppItem
}) => {
  return (
    <>
      <Card
        sx={{
          width: "100%",
          padding: "1%",
          paddingTop: "0px",
          paddingBottom: "0px"
        }}
      >
        <CardContent>
          <Stack spacing={2} direction="row" alignItems="center">
            <img src="whatsapp.png" id="social" style={{ width: "30px" }}></img>
            <CustomLink href={whatsAppItem.link} target="_blank">
              <span style={{ textAlign: "center" }}>{whatsAppItem.title}</span>
            </CustomLink>
          </Stack>
        </CardContent>
      </Card>
      <br />
    </>
  );
};

export const CustomLink = styled.a`
  text-decoration: none;
  color: black;
`;
