import AddHomeOutlinedIcon from "@mui/icons-material/AddHomeOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import EmojiObjectsOutlinedIcon from "@mui/icons-material/EmojiObjectsOutlined";
import LocalMoviesOutlinedIcon from "@mui/icons-material/LocalMoviesOutlined";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import Backdrop from "@mui/material/Backdrop";
import SpeedDial, {
  SpeedDialProps,
  speedDialClasses
} from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import React, { useState } from "react";
import styled from "styled-components";

export interface Action {
  icon: string;
  name: string;
  key: string;
  link: string;
}
const actions = [
  {
    icon: <CategoryOutlinedIcon />,
    name: "category",
    key: "Category",
    Style: "color : black[500]"
  },
  {
    icon: <AddHomeOutlinedIcon />,
    name: "add",
    key: "add_service",
    Style: "color : orange[500]",
    link: "https://forms.gle/qDAqxGvB7UMxWdRc9"
  },
  {
    icon: <LocalMoviesOutlinedIcon />,
    name: "page3",
    key: "Page_3",
    Style: "color : pink[500]"
  },
  {
    icon: <RateReviewOutlinedIcon />,
    name: "feedback",
    key: "feedback",
    link: "https://forms.gle/EPVVsGsyDpYL9d8H8"
  }
];

interface SpeedDialComponentProps {
  selectedCategory: (category: string) => void;
}

export const SpeedDialComponent: React.FC<SpeedDialComponentProps> = ({
  selectedCategory
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [direction, setDirection] =
    useState<SpeedDialProps["direction"]>("down");

  const navigator = (action: any | undefined) => {
    console.log("navigator ::", action);
    if (action) {
      switch (action.key) {
        case "feedback":
        case "add_service":
          window.location.href = action.link;
          break;
        case "Page_3":
          selectedCategory("Page 3");
          break;
        case "Event":
          selectedCategory("Event");
          break;
        case "Category":
          selectedCategory("Category");
      }
    }
  };

  return (
    <>
      <CustomBackDrop open={open} />
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{
          position: "absolute",
          top: 100,
          right: 8,
          blockSize: 0,
          minBlockSize: 1
        }}
        icon={<EmojiObjectsOutlinedIcon />}
        direction={direction}
        FabProps={{
          sx: {
            bgcolor: "#000",
            "&:hover": {
              bgcolor: "#000"
            },
            width: "2rem",
            height: "2rem"
          }
        }}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            classes={speedDialClasses}
            tooltipOpen
            onClick={(e) => navigator(action)}
          />
        ))}
      </SpeedDial>
    </>
  );
};

export const CustomBackDrop = styled(Backdrop)`
  background-color: white !important;
`;
