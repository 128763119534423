import React, { FC, useState } from "react";
import { ProfileItem } from "../models/ProfileItem";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack
} from "@mui/material";
import { ProfileMeta } from "../models/ProfileMeta";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import styled from "styled-components";

interface ProfileListProps {
  dataList: ProfileMeta[];
}

export const ProfileListComponent: FC<ProfileListProps> = ({ dataList }) => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const populateList = (index: number, profileItem: ProfileItem) => {
    return (
      <>
        <Typography
          sx={{ width: "100%", flexShrink: 0 }}
          style={{ fontWeight: "bold" }}
        >
          {profileItem.entityType === "Person" && (
            <>
              <Stack spacing={2} direction="row" alignItems="center">
                <AccountCircleOutlinedIcon />
                <Typography noWrap>{profileItem.name}</Typography>
              </Stack>
            </>
          )}
          {profileItem.entityType === "Building" && (
            <>
              <Stack spacing={2} direction="row" alignItems="center">
                <HomeWorkIcon />
                <Typography noWrap>{profileItem.name}</Typography>
              </Stack>
            </>
          )}
          {profileItem.entityType === "Whatsapp" && (
            <Stack spacing={2} direction="row" alignItems="center">
              <img
                src="whatsapp.png"
                id="social"
                style={{ width: "30px" }}
              ></img>
              <CustomLink href={profileItem.link} target="_blank">
                <span>{profileItem.name}</span>
              </CustomLink>
            </Stack>
          )}
        </Typography>
        {profileItem.entityType != "Whatsapp" && (
          <>
            <ListItem
              alignItems="flex-start"
              style={{ paddingTop: "0px", paddingBottom: "0px" }}
            >
              <ListItemText
                primary={<React.Fragment>{profileItem.address}</React.Fragment>}
                secondary={
                  <React.Fragment>
                    {profileItem.time && (
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        <Chip
                          icon={<EventAvailableOutlinedIcon />}
                          label={profileItem.time}
                          variant="outlined"
                        />
                      </Typography>
                    )}
                  </React.Fragment>
                }
              />
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListItemText
                primary={
                  <React.Fragment>
                    <a href={`tel:${profileItem.contact}`}>
                      <Button
                        style={{
                          marginBottom: "2px",
                          fontWeight: "bold",
                          color: "#000"
                        }}
                      >
                        <LocalPhoneIcon color="primary" /> {profileItem.contact}
                      </Button>
                    </a>
                  </React.Fragment>
                }
              />
            </ListItem>
            <Divider variant="fullWidth" component="li" />
          </>
        )}
      </>
    );
  };

  const populateItems = (index: number, profileMeta: ProfileMeta) => {
    return (
      <Accordion
        expanded={expanded === `panel${index}`}
        onChange={handleChange(`panel${index}`)}
        key={index}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography
            sx={{ width: "33%", flexShrink: 0 }}
            style={{ fontWeight: "bold" }}
          >
            {profileMeta.key.toUpperCase()}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {profileMeta.profileItemList.map((item, index) =>
            populateList(index, item)
          )}
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <>
      <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
        {dataList.map((item, index) => populateItems(index, item))}
      </List>
    </>
  );
};

export const CustomLink = styled.a`
  text-decoration: none;
  color: black;
  font-weight: normal;
`;
