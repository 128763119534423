// import data from "./data.json";
import pointInPolygon from "point-in-polygon";

export const isInsideGeofence = (
  latitude: number,
  longitude: number
): boolean => {
  const geofenceCoordinates = [
    [13.082080320557877, 77.63861940128209],
    [13.08106164023456, 77.63909542727228],
    [13.081722022334587, 77.63991766142207],
    [13.080534735475645, 77.6395426021517],
    [13.081047572716695, 77.6414322967333]
  ];
  const center = {
    latitude: 13.081672847648592,
    longitude: 77.63916034221653
  };
  // const point = [13.081806391457617, 77.638831362716];
  const point = [latitude, longitude];
  const isInside = pointInPolygon(point, geofenceCoordinates);

  if (isInside) {
    const distance = calculateDistance(
      latitude,
      longitude,
      center.latitude,
      center.longitude
    );
    console.log("Distance from center:", distance); // You can choose to return the distance or use it as needed
  }

  return isInside;
};

const calculateDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number => {
  const earthRadius = 6371; // Earth's radius in kilometers

  const toRadians = (degrees: number) => (degrees * Math.PI) / 180;

  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = earthRadius * c;

  return distance;
};

export const text2Speech = (text: string) => {
  if ("speechSynthesis" in window) {
    var synthesis = window.speechSynthesis;

    // Get the first `en` language voice in the list
    var voice = synthesis.getVoices().filter(function (voice) {
      return voice.lang === "en";
    })[0];

    // Create an utterance object
    var utterance = new SpeechSynthesisUtterance(text);

    // Set utterance properties
    // utterance.voice = voice;
    // utterance.pitch = 1.5;
    // utterance.rate = 1.25;
    // utterance.volume = 0.8;

    // Speak the utterance
    synthesis.speak(utterance);
  } else {
    console.log("Text-to-speech not supported.");
  }
};
