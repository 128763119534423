import { Profile } from "../models/Profile";
import { ProfileItem } from "../models/ProfileItem";
import { ProfileMeta } from "../models/ProfileMeta";
import { getBotResponse } from "./chat.service";
import profiledata from "./profile_data.json";

export const getProfileServices = async (profile: string) => {
  let profileMetaList: ProfileMeta[] = [];
  let profileItems: ProfileItem[] = [];
  const profilList = profiledata.filter((item) => item.profile === profile);
  const serviceList = profilList[0].services;
  await serviceList.forEach(async (item) => {
    const profileMeta: ProfileMeta = {} as ProfileMeta;
    profileMeta.key = item;
    profileMeta.profileItemList = [];
    const responsedata = await getBotResponse(item);
    // console.log("responsedata.message", responsedata.dataType);
    if (responsedata.dataType && responsedata.message.length > 0) {
      responsedata.message.forEach((item1: any) => {
        const pitem: ProfileItem = {
          name: item1["title"],
          contact: item1["phone"],
          address: item1["address"],
          type: item,
          time: item1["timing"],
          entityType: item1["entityType"],
          link: item1["link"]
        } as ProfileItem;
        profileMeta.profileItemList.push(pitem);
        profileItems.push(pitem);
      });
    }
    console.log("profileMeta ::", profileMeta);
    if (profileMeta.profileItemList && profileMeta.profileItemList.length > 0) {
      profileMetaList.push(profileMeta);
    }

    console.log(profileItems.length);
  });
  console.log("profileMetaList :: ", profileMetaList);
  return profileMetaList;
};
