import { Typography } from "@mui/material";
import styled from "styled-components";

export default function MobilePage() {
  return (
    <>
      <CustomSmartPhoneDiv className="smartphone">
        <CustomDiv>
          <span>
            PicoSearch is only accessible through a mobile device. Please visit
            using a mobile device to access the website.
          </span>
        </CustomDiv>
      </CustomSmartPhoneDiv>
    </>
  );
}
export const CustomDiv = styled.div`
  margin-top: 50%;
  margin-left: 5%;
`;

export const CustomSmartPhoneDiv = styled.div`
  margin-top: 2%;
`;
