import { removeStopwords, eng, fra } from "stopword";
import faqData from "./faq_data.json";
import { FAQData } from "../models/FAQData";
import { FilteredData } from "../models/FilteredData";
import { UtilityDetails } from "../models/UtilityDetails";
import {
  ANSWER_TYPE,
  ACTIVITY_STATUS,
  ACCESS_STATUS
} from "../common/constants";
import { Status } from "../models/Status";

const politeMessages = [
  "Sorry, no results matched your search. Try looking for services like electricians, grocery stores, dentists, or childcare providers in your community",
  "No results found. I can assist you in finding local services such as mechanics, pharmacies, tutors, or fitness centers nearby",
  "Oops, nothing came up. How about trying searches for cleaning services, carpenters, veterinarians, or youth programs in your area?",
  "We couldn’t find what you’re looking for. You can search for community services like gardeners, pet services, clinics, or after-school programs.",
  "Sorry, nothing found. Try searching for categories like plumbing, home repair, medical services, or kids’ activities to find useful resources.",
  "No matches found. You can explore community-related searches like barbers, restaurants, therapists, or childcare options.",
  "No results found for your search. Let me help you by searching for local services such as painters, automotive services, health clinics, or family-friendly activities",
  "Looks like we couldn’t find a match. Try searching for community services like electricians, schools, pediatricians, or sports facilities",
  "Nothing came up for your search. You can try looking for local handyman services, pet care, health professionals, or kids’ entertainment options",
  "Sorry, no results. How about searching for services such as landscapers, gyms, doctors, or educational programs in your community?"
];

const greetingMessageList = [
  "Welcome to PICO SEARCH! Find local shops, services, and professionals like plumbers, doctors, electricians, and kids' activities near you.",
  "Hello! Need help finding something? Search for restaurants, pharmacies, mechanics, or community events with PICO SEARCH.",
  "Hi there! PICO SEARCH helps you find cleaners, tutors, doctors, and local stores in your community.",
  "Welcome! Looking for services? Find handyman services, clinics, childcare providers, and more with PICO SEARCH.",
  "Hello and welcome! Use PICO SEARCH to explore your area for gardeners, dentists, hardware stores, or youth programs.",
  "Hi! Need to find something nearby? Search for fitness centers, veterinarians, local cafes, or school services with PICO SEARCH.",
  "Welcome to PICO SEARCH! Your go-to for finding electricians, plumbers, pharmacies, and kids' services near you.",
  "Greetings! PICO SEARCH helps you locate mechanics, restaurants, health services, or family-friendly activities in your area",
  "Hello there! Use PICO SEARCH to discover local home repair services, medical professionals, tutors, and entertainment options.",
  "Welcome aboard! We’re here to help you find barbers, clinics, plumbers, or community events with ease."
];

export const getGreetingMessage = () => {
  const randomGreetMessage =
    greetingMessageList[Math.floor(Math.random() * greetingMessageList.length)];
  return randomGreetMessage;
};

export const getBotResponse = async (query: string) => {
  if (query.length <= 3) {
    return invalidQueryMessage();
  }
  query = query.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "");
  // console.log("getBotResponse : query :: ", query);
  const filteredQuery = removeStopwords(query.toLowerCase().split(" "));
  // console.log("getBotResponse : filteredQuery :: ", filteredQuery);
  let matchPErcentageList: FilteredData[] = [];
  faqData.forEach((item) => {
    let status = item.status as Status;
    if (item && item.keywords) {
      if (
        status.activity === ACTIVITY_STATUS.ACTIVE &&
        status.access === ACCESS_STATUS.PUBLIC
      ) {
        let match = matchPerc(item.keywords, filteredQuery);
        if (match && match > 0) {
          const filteredData: FilteredData = populateMessage(match, item);
          matchPErcentageList.push(filteredData);
        }
      } else if (
        status.activity === ACTIVITY_STATUS.ACTIVE &&
        status.access === ACCESS_STATUS.PRIVATE
      ) {
        console.log("its private data....");
      }
    }
  });
  if (matchPErcentageList.length > 0) {
    matchPErcentageList.sort((a, b) =>
      a.matchingRate > b.matchingRate ? -1 : 1
    );
    return matchPErcentageList[0];
  } else {
    const randomPoliteMessage =
      politeMessages[Math.floor(Math.random() * politeMessages.length)];
    const filteredData: FilteredData = {} as FilteredData;
    filteredData.message = randomPoliteMessage;
    filteredData.answer = randomPoliteMessage;
    filteredData.service = "Bot";
    filteredData.answerType = ANSWER_TYPE.TEXT;
    return filteredData;
  }
};

export const invalidQueryMessage = () => {
  const filteredData: FilteredData = {} as FilteredData;
  const randomPoliteMessage =
    politeMessages[Math.floor(Math.random() * politeMessages.length)];
  filteredData.message = randomPoliteMessage;
  filteredData.answer = "";
  filteredData.service = "Bot";
  filteredData.answerType = ANSWER_TYPE.TEXT;
  return filteredData;
};

export const populateMessage = (match: number, faqData: FAQData) => {
  const filteredData: FilteredData = {} as FilteredData;
  filteredData.matchingRate = match;
  filteredData.service = faqData.service;
  filteredData.answerType = faqData.answer_type;
  filteredData.dataType = faqData.data_type;
  switch (faqData.answer_type) {
    case ANSWER_TYPE.TEXT:
      filteredData.message = `${faqData.message} ${faqData.answer}`;
      filteredData.answer = faqData.answer;
      break;
    case ANSWER_TYPE.CARD:
      filteredData.message = faqData.answer as UtilityDetails;
      filteredData.answer = faqData.answer;
      break;
    case ANSWER_TYPE.PHONE:
      filteredData.message = faqData.answer;
      filteredData.answer = faqData.answer;
      break;
    case ANSWER_TYPE.LIST:
      filteredData.message = faqData.answer;
      filteredData.answer = faqData.answer;
      break;
  }
  return filteredData;
};
export const matchPerc = (wordList: string[], text: string[]) => {
  let foundList = [];
  // console.log("matchPerc : wordList :: ", wordList);
  text.forEach((item) => {
    let temp = wordList.filter((word) => {
      // console.log("word :: ", word);
      if (word.indexOf(item) != -1) {
        foundList.push(word);
      }
    });
    return temp;
  });
  return foundList.length;
};
