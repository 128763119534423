import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import { FC } from "react";

interface ProfileGridProps {
  openCategory: (profile: string) => void;
}

const StyledBox = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800]
}));

export const ProfileGridComponent: FC<ProfileGridProps> = ({
  openCategory
}) => {
  return (
    <>
      <StyledBox
        sx={{
          px: 2,
          pb: 2,
          height: "100%",
          overflow: "auto",
          backgroundColor: "white",
          marginTop: "30px",
          paddingLeft: "10px"
        }}
      >
        <Grid container spacing={6}>
          <Grid item xs={6} md={4}>
            <Grid container spacing={1}>
              <Grid container item spacing={1}>
                <CustomButton
                  variant="outlined"
                  style={{ color: "black" }}
                  onClick={(e) => openCategory("Whatsapp")}
                >
                  <img
                    src="whatsapp.png"
                    id="social"
                    style={{ width: "50px" }}
                  ></img>
                </CustomButton>
              </Grid>
              <Grid container item spacing={1}>
                <span style={{ textAlign: "center", width: "50%" }}>
                  Whatsapp Groups
                </span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} md={4}>
            <Grid container spacing={1}>
              <Grid container item spacing={1}>
                <CustomButton
                  variant="outlined"
                  onClick={(e) => openCategory("Senior Citizen")}
                >
                  <img
                    src="grandparents.png"
                    id="grandparents"
                    style={{ width: "80px" }}
                  ></img>
                </CustomButton>
              </Grid>
              <Grid container item spacing={1} style={{ textAlign: "center" }}>
                <span>Senior Citizen</span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} md={4}>
            <Grid container spacing={1}>
              <Grid container item spacing={1}>
                <CustomButton
                  variant="outlined"
                  style={{ color: "black" }}
                  onClick={(e) => openCategory("Couple")}
                >
                  <img
                    src="parents.png"
                    id="parents"
                    style={{ width: "50px" }}
                  ></img>
                </CustomButton>
              </Grid>
              <Grid container item spacing={1}>
                <span style={{ textAlign: "center", width: "58%" }}>
                  Family
                </span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} md={4}>
            <Grid container spacing={1}>
              <Grid container item spacing={1}>
                <CustomButton
                  variant="outlined"
                  style={{ color: "black" }}
                  onClick={(e) => openCategory("Man")}
                >
                  <img src="man.png" id="man" style={{ width: "50px" }}></img>
                </CustomButton>
              </Grid>
              <Grid container item spacing={1}>
                <span style={{ textAlign: "center", width: "58%" }}>Man</span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} md={4}>
            <Grid container spacing={1}>
              <Grid container item spacing={1}>
                <CustomButton
                  variant="outlined"
                  style={{ color: "black" }}
                  onClick={(e) => openCategory("Woman")}
                >
                  <img
                    src="woman.png"
                    id="woman"
                    style={{ width: "50px" }}
                  ></img>
                </CustomButton>
              </Grid>
              <Grid container item spacing={1}>
                <span style={{ textAlign: "center", width: "58%" }}>Woman</span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} md={4}>
            <Grid container spacing={1}>
              <Grid container item spacing={1}>
                <CustomButton
                  variant="outlined"
                  style={{ color: "black" }}
                  onClick={(e) => openCategory("Kids")}
                >
                  <img
                    src="school.png"
                    id="kid"
                    style={{ width: "50px" }}
                  ></img>
                </CustomButton>
              </Grid>
              <Grid container item spacing={1}>
                <span style={{ textAlign: "center", width: "58%" }}>Kid</span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} md={4}>
            <Grid container spacing={1}>
              <Grid container item spacing={1}>
                <CustomButton
                  variant="outlined"
                  style={{ color: "black" }}
                  onClick={(e) => openCategory("Pet")}
                >
                  <img src="pet.png" id="pet" style={{ width: "50px" }}></img>
                </CustomButton>
              </Grid>
              <Grid container item spacing={1}>
                <span style={{ textAlign: "center", width: "58%" }}>Pet</span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </StyledBox>
    </>
  );
};
export const CustomButton = styled(Button)`
  border: 1px solid #ddd; /* Gray border */
  border-radius: 20px; /* Rounded border */

  width: 80px;
  height: 80px;
  margin-top: 10px;
  /* Set a small width */
`;
