export interface ChatData {
  message: any;
  answer: any;
  userType: string;
  answerType: string;
  dataType: string;
  service?: string;
}

export enum UserType {
  BOT = "BOT",
  USER = "USER"
}
