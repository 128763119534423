import MicIcon from "@mui/icons-material/Mic";
import SendIcon from "@mui/icons-material/Send";
import { Grid } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import { FC, useState, useEffect } from "react";

interface ChatControlProps {
  sendMsg: (msg: string) => void;
}

export const ChatControlBar: FC<ChatControlProps> = ({ sendMsg }) => {
  const [sendIconFlag, setSendIconFlag] = useState<Boolean>(false);
  const [message, setMessage] = useState<string>("");

  const [listening, setListening] = useState(false);
  const [transcript, setTranscript] = useState("");
  const [recognition, setRecognition] = useState<any | null>(null);

  const sendMessage = () => {
    sendMsg(message);
    setMessage("");
    setSendIconFlag(false);
  };

  useEffect(() => {
    const SpeechRecognition =
      (window as any).SpeechRecognition ||
      (window as any).webkitSpeechRecognition;
    if (SpeechRecognition) {
      const recog = new SpeechRecognition();
      recog.continuous = true;
      recog.interimResults = true;
      recog.lang = "en-US";

      recog.onstart = () => {
        setListening(true);
      };

      recog.onend = () => {
        setListening(false);
      };

      recog.onresult = (event: any) => {
        let finalTranscript = "";
        let interimTranscript = "";
        for (let i = event.resultIndex; i < event.results.length; i++) {
          const transcript = event.results[i][0].transcript;
          if (event.results[i].isFinal) {
            finalTranscript += transcript + " ";
            setMessage(finalTranscript.trim());
            sendMsg(finalTranscript.trim());
            setMessage("");
          } else {
            interimTranscript += transcript;
            setMessage(interimTranscript.trim());
          }
        }
        setTranscript((prev) => prev + finalTranscript);
        // Stop recognition after getting the result
        if (recognition) {
          recognition.stop();
        }
      };
      setRecognition(recog);
      stopListening();
    } else {
      alert("Speech Recognition API is not supported in this browser.");
    }
  }, []);

  const startListening = () => {
    setTranscript("");
    if (recognition && !listening) {
      recognition.start();
    }
  };

  const stopListening = () => {
    if (recognition && listening) {
      recognition.stop();
    }
  };

  const handleMouseDown = () => {
    startListening();
  };

  const handleMouseUp = () => {
    stopListening();
  };

  const handleTouchStart = () => {
    startListening();
  };

  const handleTouchEnd = () => {
    stopListening();
  };

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" color="inherit" sx={{ top: "auto", bottom: 0 }}>
        <Toolbar>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <TextField
                value={message}
                onChange={(e) => {
                  const data = e.target.value;
                  setMessage(data);
                  data.length > 0
                    ? setSendIconFlag(true)
                    : setSendIconFlag(false);
                }}
                multiline
                rows={1}
                style={{ padding: "5px", width: "100%" }}
                placeholder="Enter your query..."
              />
            </Grid>
            <Grid item xs={2}>
              {sendIconFlag && (
                <IconButton
                  aria-label="fingerprint"
                  color="secondary"
                  style={{ marginTop: "12px", color: "#08193a" }}
                  onClick={sendMessage}
                >
                  <SendIcon />
                </IconButton>
              )}
              {!sendIconFlag && (
                <>
                  {!listening && (
                    <IconButton
                      aria-label="fingerprint"
                      color="secondary"
                      style={{ marginTop: "12px", color: "#08193a" }}
                      onMouseDown={handleMouseDown}
                      onMouseUp={handleMouseUp}
                      onTouchStart={handleTouchStart}
                      onTouchEnd={handleTouchEnd}
                    >
                      <MicIcon />
                    </IconButton>
                  )}

                  {listening && (
                    <>
                      <IconButton
                        aria-label="fingerprint"
                        color="secondary"
                        style={{ marginTop: "12px", color: "#08193a" }}
                        onClick={stopListening}
                      >
                        <StopCircleIcon />
                      </IconButton>
                    </>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};
