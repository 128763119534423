import { PaletteMode } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import * as React from "react";
import { CategoryMenuComponent } from "./components/CategoryMenuComponent";
import CustomAppBar from "./components/CustomAppBar";
import { Page3Component } from "./components/Page3Component";
import { SpeedDialComponent } from "./components/SpeedDialComponent";
import getLPTheme from "./getLPTheme";
import { ChatBot } from "./pages/ChatBot";
import { PAGE_TYPE } from "./common/constants";

export default function LandingPage() {
  const [mode, setMode] = React.useState<PaletteMode>("light");
  const [showCustomTheme, setShowCustomTheme] = React.useState(false);
  const LPtheme = createTheme(getLPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });
  const [showPage3, setShowPage3] = React.useState(false);
  const [showCategory, setShowCategory] = React.useState(false);
  const [showChatBot, setShowChatBot] = React.useState(true);
  const [page3Posts, setPage3Posts] = React.useState([]);

  const toggleColorMode = () => {
    setMode((prev) => (prev === "dark" ? "light" : "dark"));
  };
  const [title, setTitle] = React.useState("");

  const selectedCategory = async (category: any) => {
    setTitle(category);
    switch (category) {
      case PAGE_TYPE.PAGE3_PAGE:
        setShowPage3(true);
        break;
      case PAGE_TYPE.CATEGORY_PAGE:
        setShowCategory(true);
        break;
    }
    setShowChatBot(false);
  };

  const close = (title: string) => {
    switch (title) {
      case PAGE_TYPE.PAGE3_PAGE:
        setShowPage3(false);
        break;
      case PAGE_TYPE.CATEGORY_PAGE:
        setShowCategory(false);
        break;
    }
    setShowChatBot(true);
  };

  React.useEffect(() => {}, [showPage3]);

  return (
    <>
      <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
        <CssBaseline />
        <CustomAppBar
          mode={mode}
          toggleColorMode={toggleColorMode}
          selectedCategory={selectedCategory}
        />

        {showChatBot && <ChatBot></ChatBot>}

        {showCategory && (
          <CategoryMenuComponent
            data={page3Posts}
            show={showCategory}
            close={close}
            title={title}
          ></CategoryMenuComponent>
        )}
        {showPage3 && (
          <Page3Component
            data={page3Posts}
            show={showPage3}
            close={close}
            title={title}
          ></Page3Component>
        )}
      </ThemeProvider>
      <SpeedDialComponent
        selectedCategory={selectedCategory}
      ></SpeedDialComponent>
    </>
  );
}
