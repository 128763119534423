import { FC } from "react";
import { UtilityCard } from "../pages/utility.card.component";
import { ChatData, UserType } from "../models/ChatData";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import { PhoneContact } from "../models/PhoneContact";
import styled from "@emotion/styled";
import { Chip, Grid, ListItemText } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { DATA_TYPE } from "../common/constants";
import Typography from "@mui/material/Typography";
import { Address } from "../models/Address";
import List from "@mui/material/List";
import { AddressTypeComponent } from "./answers/AddressTypeComponent";
import { UtilityTypeComponent } from "./answers/UtilityTypeComponent";
import { UtilityDetails } from "../models/UtilityDetails";
import { WhatsAppTypeComponent } from "./answers/WhatsAppTypeComponent";
import { WhatsApp } from "../models/WhatsApp";

interface ResponseComponentProps {
  chatData: ChatData;
}

const ResponseComponent: FC<ResponseComponentProps> = ({ chatData }) => {
  const getPhoneContact = () => {
    return chatData.message as PhoneContact;
  };

  const getListItems = () => {
    return chatData.message as any[];
  };

  const populateListComponent = () => {
    return getListItems().map((item: any, index: number) =>
      getServiceResponse(item, index)
    );
  };
  const getServiceResponse = (item: any, index: number) => {
    console.log("chatData.dataType :: ", chatData);
    switch (chatData.dataType) {
      case DATA_TYPE.TRAIN:
        return (
          <>
            <ListItemText
              style={{ backgroundColor: "#fff" }}
              className="type"
              secondary={`${item["station"]} | ${item["distance"]}`}
            />
          </>
        );
      case DATA_TYPE.ADDRESS:
        return (
          <AddressTypeComponent
            addressItem={item as Address}
          ></AddressTypeComponent>
        );
      case DATA_TYPE.EMAIL:
        return (
          <>
            <CustomChip
              label={item}
              icon={<MailOutlineIcon color="primary" />}
              variant="outlined"
            />
          </>
        );
      case DATA_TYPE.PHONE:
        return (
          <>
            <UtilityTypeComponent
              utilityItem={item as UtilityDetails}
            ></UtilityTypeComponent>
          </>
        );
      case DATA_TYPE.WHATSAPP_LINKS:
        return (
          <WhatsAppTypeComponent
            whatsAppItem={item as WhatsApp}
          ></WhatsAppTypeComponent>
        );
    }
  };

  return (
    <>
      {chatData.answerType === "card" && (
        <List
          sx={{
            width: "100%",
            maxWidth: 360,
            paddingTop: "-1px"
          }}
        >
          <span style={{ fontWeight: "bold" }}>PICO BOT</span>
          <UtilityCard details={chatData.message}></UtilityCard>
        </List>
      )}
      {chatData.answerType === "list" && (
        <List
          sx={{
            width: "100%",
            maxWidth: 360,
            paddingTop: "-1px"
          }}
        >
          <span style={{ fontWeight: "bold" }}>PICO BOT</span>

          {populateListComponent()}
        </List>
      )}
      {chatData.answerType === "phone" && (
        <List
          key={chatData.message}
          sx={{
            width: "100%",
            maxWidth: 360,
            paddingTop: "-1px"
          }}
        >
          <span style={{ fontWeight: "bold" }}>PICO BOT</span>

          {populateListComponent()}
        </List>
      )}
      {chatData.answerType === "text" && (
        <>
          {chatData.userType === UserType.BOT && (
            <ListItemText
              style={{
                backgroundColor: "#fff",
                paddingTop: "0px",
                paddingBottom: "0px"
              }}
              primary={<span style={{ fontWeight: "bold" }}>PICO BOT</span>}
              secondary={
                <Typography className="type" component="span">
                  {chatData.message}
                </Typography>
              }
            />
          )}

          {chatData.userType === UserType.USER && (
            <ListItemText
              style={{
                backgroundColor: "#fff",
                textAlign: "right",
                marginTop: "50px",
                background: "#eaf0f5",
                borderRadius: "20px",
                paddingTop: "10px",
                paddingRight: "10px",
                paddingBottom: "5px"
              }}
              primary={<span style={{ fontWeight: "bold" }}>YOU</span>}
              secondary={
                <Typography component="span" style={{ paddingBottom: "5px" }}>
                  {chatData.message}
                </Typography>
              }
            />
          )}
        </>
      )}
    </>
  );
};

export const CustomIcon = styled(ContactPhoneIcon)`
  line-height: 1;
`;

export const CustomChip = styled(Chip)`
  margin-left: 10px;
`;
export const CustomGrid = styled(Grid)`
  margin-top: 0px;
`;

export default ResponseComponent;
