import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import React, { useEffect } from "react";

interface GeoRestrictionDialogProps {
  successCallback: (position: GeolocationPosition) => void;
  errorCallback: (error: GeolocationPositionError) => void;
  checkGeoFenceFlag: false;
}

export const GeoRestrictionDialog: React.FC<GeoRestrictionDialogProps> = ({
  successCallback,
  errorCallback,
  checkGeoFenceFlag
}) => {
  const [open, setOpen] = React.useState(false);

  const checkGeoFence = () => {
    if ("geolocation" in navigator) {
      console.log("inside if loop...geo");
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    if (checkGeoFenceFlag) {
      checkGeoFence();
    }
  }, []);

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"NIKOO HOMES-II Owners's Search"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Accessing the restricted data outside the premises is not permitted.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};
