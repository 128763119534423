import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Typography
} from "@mui/material";
import { red } from "@mui/material/colors";
import { FC } from "react";
import { UtilityDetails } from "../models/UtilityDetails";

interface UtilityCardProps {
  details: UtilityDetails;
}

export const UtilityCard: FC<UtilityCardProps> = ({ details }) => {
  return (
    <>
      {details && (
        <Card>
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                {/* {details.name.charAt(0).toLocaleUpperCase()} */}
              </Avatar>
            }
            title={details.name}
            subheader={details.link}
          />
          <CardContent>
            <b>Address:</b>
            <Typography variant="body2" color="text.secondary">
              {details.address}
            </Typography>
            <b>Mobile:</b>
            <Typography variant="body2" color="text.secondary">
              {details.contact}
            </Typography>
            <b>Google link:</b>{" "}
            <a href={details.googleLink} target="_blank" rel="noreferrer">
              click here
            </a>
          </CardContent>
        </Card>
      )}
    </>
  );
};
