import { Button, Card, CardContent, Typography } from "@mui/material";
import { FC } from "react";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { UtilityDetails } from "../../models/UtilityDetails";

interface UtilityTypeComponentProps {
  utilityItem: UtilityDetails;
}

export const UtilityTypeComponent: FC<UtilityTypeComponentProps> = ({
  utilityItem
}) => {
  return (
    <>
      <Card
        sx={{
          width: "100%",
          padding: "1%",
          paddingTop: "0px",
          paddingBottom: "0px"
        }}
      >
        <CardContent>
          <Typography>{utilityItem.name}</Typography>
        </CardContent>
        {utilityItem.contact && (
          <a href={`tel:${utilityItem.contact}`}>
            <Button
              style={{
                marginLeft: "10px",
                marginBottom: "2px",
                fontWeight: "bold",
                color: "#000"
              }}
            >
              <LocalPhoneIcon color="primary" /> {utilityItem.contact}
            </Button>
          </a>
        )}
      </Card>
      <br />
    </>
  );
};
