import * as React from "react";
import { Chip, PaletteMode } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { CustomAppBarStyleContainer } from "./CustomAppBarStyleComponent";
import styled from "styled-components";
import PersonPinCircleOutlinedIcon from "@mui/icons-material/PersonPinCircleOutlined";

interface CustomAppBarProps {
  mode: PaletteMode;
  toggleColorMode: () => void;
  selectedCategory: (category: string) => void;
}

function CustomAppBar({
  mode,
  toggleColorMode,
  selectedCategory
}: CustomAppBarProps) {
  const handleClick = () => {
    console.info("You clicked the Chip.");
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar position="sticky">
        <CustomAppBarStyleContainer
          maxWidth="xl"
          style={{ background: "white" }}
          id="app_bar_style_id"
        >
          <Toolbar disableGutters>
            <img
              src="search-final-icon.png"
              style={{ width: "45px", height: "45px", marginLeft: "10px" }}
              id="tool_bar_logo"
            ></img>
            <CustomTitle id="custom_title_id">PICO SEARCH</CustomTitle>
          </Toolbar>

          <Chip
            id="apartment_id"
            label="560064 | NIKOO HOME - II"
            clickable
            icon={<PersonPinCircleOutlinedIcon style={{ color: "black" }} />}
            variant="outlined"
            onClick={handleClick}
            style={{
              marginBottom: "2%",
              marginLeft: "25%",
              fontSize: "15px",
              marginRight: "22%",
              color: "black",
              marginTop: "0px"
            }}
          />
        </CustomAppBarStyleContainer>
      </AppBar>
    </>
  );
}

export default CustomAppBar;
export const CustomTitle = styled.h1`
  font-size: 25px;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px;
  margin-left: 0%;
  color: black;
  margin-bottom: 0px;
`;
