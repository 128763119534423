import { Global } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import UndoOutlinedIcon from "@mui/icons-material/UndoOutlined";
import { Grid } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Typography from "@mui/material/Typography";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import React, { FC, useState } from "react";
import { ProfileMeta } from "../models/ProfileMeta";
import { getProfileServices } from "../services/profile.service";
import { ProfileGridComponent } from "./ProfileGridComponent";
import { ProfileListComponent } from "./ProfileListComponent";

const drawerBleeding = 56;
interface CategoryMenuProps {
  window?: () => Window;
  show: boolean;
  data: any;
  close: (title: string) => void;
  title: string;
}

const StyledBox = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800]
}));

const Puller = styled("div")(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)"
}));

export const CategoryMenuComponent: FC<CategoryMenuProps> = ({
  window,
  show,
  close,
  title
}) => {
  const [open, setOpen] = React.useState(show);

  const [profileContent, setProfileContent] = useState(false);
  const [profileList, setProfileList] = useState<ProfileMeta[]>([]);
  const [currentCategory, setCurrentCategory] = useState(String);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const handleClose = () => {
    close("Category");
  };

  const handleBack = () => {
    setProfileContent(false);
  };

  const openCategory = async (category: string) => {
    let profileMeta: ProfileMeta[] = await getProfileServices(category);
    setProfileList(profileMeta);
    setProfileContent(true);
    setCurrentCategory(category);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      {open && (
        <>
          <CssBaseline />
          <Global
            styles={{
              ".MuiDrawer-root > .MuiPaper-root": {
                height: `calc(85% - ${drawerBleeding}px)`,
                overflow: "visible"
              }
            }}
          />
          <SwipeableDrawer
            container={container}
            anchor="bottom"
            open={open}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            swipeAreaWidth={drawerBleeding}
            disableSwipeToOpen={false}
            ModalProps={{
              keepMounted: true
            }}
          >
            <StyledBox
              sx={{
                position: "absolute",
                top: -drawerBleeding,
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                visibility: "visible",
                right: 0,
                left: 0
              }}
            >
              <Puller />
              <Typography sx={{ p: 2, width: "100%" }}>
                {profileContent && (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={9} md={8}>
                        <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                          Category / {currentCategory}
                        </span>
                      </Grid>
                      <Grid item xs={3} md={2}>
                        <UndoOutlinedIcon
                          style={{ marginLeft: "0px", paddingTop: "2px" }}
                          onClick={handleBack}
                        ></UndoOutlinedIcon>
                        <CloseIcon
                          style={{ paddingTop: "2px" }}
                          onClick={handleClose}
                        ></CloseIcon>
                      </Grid>
                    </Grid>
                  </>
                )}
                {!profileContent && (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={9} md={8}>
                        <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                          {title}
                        </span>
                      </Grid>
                      <Grid item xs={3} md={2}>
                        <CloseIcon
                          style={{ marginLeft: "30px", paddingTop: "2px" }}
                          onClick={handleClose}
                        ></CloseIcon>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Typography>
            </StyledBox>

            {profileList && profileList.length > 0 && profileContent && (
              <StyledBox
                sx={{
                  px: 2,
                  pb: 2,
                  height: "100%",
                  overflow: "auto",
                  backgroundColor: "white",
                  marginTop: "30px",
                  paddingLeft: "15px"
                }}
              >
                <ProfileListComponent
                  dataList={profileList}
                ></ProfileListComponent>
              </StyledBox>
            )}
            {!profileContent && (
              <StyledBox
                sx={{
                  px: 2,
                  pb: 2,
                  height: "100%",
                  overflow: "auto",
                  backgroundColor: "white",
                  marginTop: "30px",
                  paddingLeft: "50px"
                }}
              >
                <ProfileGridComponent
                  openCategory={openCategory}
                ></ProfileGridComponent>
              </StyledBox>
            )}
          </SwipeableDrawer>
        </>
      )}
    </>
  );
};
