import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import React, { FC } from "react";

interface NotificationComponentProps {
  show: boolean;
  message: string;
  flag: boolean;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const NotificationComponent: FC<NotificationComponentProps> = ({
  show,
  message,
  flag
}) => {
  return (
    <>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar open={show} autoHideDuration={6000}>
          <Alert severity={flag ? "success" : "error"} sx={{ width: "100%" }}>
            {message}!
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default NotificationComponent;
